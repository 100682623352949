.tabItemConfig{
    width: 120px;
    height: 32px;
}

@media (min-width: 1200px){
  .tabItemConfigBig{
    width: 160px !important;
  }
}

@media (max-width: 767px){
    .lastTab{
      display: none;
    }
    
}   
@media (max-width: 1199px){
    .tabItemConfig{
      padding-right: 16px;
    }
}