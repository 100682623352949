.searchBox {
    background-color: #1c2930 !important;
    border-color: #28373E;
    color: #fff !important;
}

.modelSearchBox {
    background-color: #28373E !important;
    border-color: #1c2930;
    color: #fff !important;
}

.practiceCardBox {
    background-color: #28373E;
    border-color: #28373E;
}

.cardBox {
    background-color: #28373E !important;
    color: #fff;
}

.cardBorder {
    border-color: #BECDD5;
}

.tableTextColor {
    border-color: #fff;
    border-bottom: 1px solid;
}

.resourceTableColor {
    color: #fff;
    border-bottom: 1px solid;
}

.canvasBody {
    background-color: #1c2930;
    color: white;
}

.canvasText {
    color: #fff;
    text-decoration: none;
}

.canvasText:hover {
    font-weight: bold;
    color: #fff;
}

.searchBox1 {
    background-color: #1c2930;
    border-color: #28373E;
}

.chatShell {
    background-color: #28373E;
}

.chatAnswer {
    background-color: #1c2930;
    color: #fff;
}

.collapseCard {
    background-color: #28373E;
    border-color: none !important;
}

.collapseButton .collupse-button.btn-primary:active,
.collapseButton .collupse-button.btn-primary:focus,
.collapseButton .collupse-button.btn.btn-primary {
    background-color: #1c2930 !important;
    box-shadow: none;
    padding: 0;
    margin-bottom: 0;
}

.collapseButton .collupse-button.btn-primary:hover {
    color: #fff !important;
}

.collapseButton .card {
    border-color: none !important;
}

.collapseButton .card-body {
    padding: 0;
}


.journalBox {
    background-color: #28373E;
}

.practiceLibraryTextColor {
    border-color: #fff !important;
    border-bottom: 1px solid #fff;
}

.meetingCard {
    background-color: #28373E;
}


.tabColor {
    border-bottom: 2px solid #ff8568 !important;
}

.tabColor div {
    font-weight: bold !important;
    color: #fff !important;
}

.tabMain div {
    color: #fff !important;
}

.textColor {
    color: #fff !important;
}

.hamburgerMenuColor {
    color: #fff !important;
}

.horizontalShellCard {
    background: #28373E;
}

.modelBox {
    background: #28373E;
}

.clientSearchBox {
    background-color: #1c2930 !important;
    border-color: #BECDD5;
    color: #fff !important;
}

.mutltiBox .dropdown-heading {
    background-color: #1c2930;
}

.mutltiBox .dropdown-container {
    border-color: #BECDD5 !important;
    border-radius: 0px;
}

.meetingTable {
    background-color: #28373E !important;
    color: #fff !important;
}

.userSearchBox {
    background-color: #28373E !important;
    border-color: #BECDD5;
    color: #fff !important;
}

.dateInputBox {
    background-color: #28373E !important;
    border-color: #1c2930 !important;
    color: #fff !important;
}

.surveyDateBox {
    background-color: #1c2930 !important;
    border-color: #28373E !important;
    color: #fff !important;
}

.borderBottom {
    border-bottom: 2px solid #2B404C;
}

.disableTab div {
    color: #2d5362 !important;
    /* color: red !important; */
}

.dashboardButton {
    background-color: #3f4b54 !important;
}

.filter-dark {
    filter: drop-shadow(2px 2px 5px rgba(156, 156, 156, 0.25)) !important;
}

.practiceCard {
    background: #28373E;
}

.rightImgDark {
    background: #1c2930 !important;
    border: 0px solid;
}

.summeryDark button.accordion-button {
    background: #28373E;
    color: white;
}

/* .summeryDark .accordion-button::after {
    background: white;
} */

.summeryDark .accordion-body {
    background: #28373E;
}

.tab-selected-dark {
    border-bottom: 2px solid #ff8568 !important;
}

.tab-selected-dark div {
    font-weight: bold !important;
    color: #fff !important;
}

.tabDark div {
    color: #fff !important;
}