.concept-select-config{
    height: 64px;
}
.concept-subtitle{
    font-size: 18px;    
}

.warningHeight{
    height: 76px;
}

@media (min-width: 1200px) {
    .contentShellLeftConfig{
        width:100% !important;
    }
    .marginTopShellMain{
        top: 76px;
        bottom: 88px;
        z-index: 100;
    }
  }

  @media (max-width: 767px) {
    .transcriptConfig{
        margin-top: 145px;
    }
  }

  @media (max-width: 767px){
    .chatScreenConfigThinkIntro{
       margin-bottom: 45px;
        }
    }
    
    @media (min-width: 768px) and (max-width: 1199px){ 
    .chatScreenConfigThinkIntro{
        margin-bottom: 25px;
    }
}