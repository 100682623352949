.nvlinks-1 {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 40vh;
}

.lnk-pointer {
    text-decoration: none;
    color: black
}

.lnk-pointer:hover {
    font-weight: bold;
    color: black;
}

.off-cnavas {
    background-color: black;
}

.off-canvas {
    background-color: #28373E;
}