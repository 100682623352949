.card-title{
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
}

@media (max-width: 575px){
    .flex-config{
        flex-direction: column;
        margin-top:24px;
    }  
}

@media (max-width: 1199px) {
    .button-config{
        margin-top: 16px;
        margin-bottom: 10px;
    }
}
