@media (min-width: 1200px) { 
    .leftSideConfig{
        width: 65.59%;
    }
    .rightSideConfig{
        width: 31.18%;
    }
    .mainShellConfig{
        width: 100%;
    }
    .contentShellConfig-half{
        width: 48%;
    }
    .contentShellConfig-75{
        width: 75%;
    }
    .leftSideConfig2{
        width: 65.59%;        
    }
    .rightSideConfig2{
        width: 31.18%;
    }
 }

 @media (max-width: 767px) {
    .contentShellConfig-half{
        width: 100%;
    } 
    .profile-wrap-config{
        flex-direction: column;    
    }   
    .leftSideConfig2{
        margin-bottom: 40px;
    }
 }
 @media (min-width: 768px) and (max-width: 1199px) {
    .contentShellConfig-half{
        width: 48%;
    }  
 }

