
@media (max-width: 767px) {
    .d-flex.custom-program-title-flex {
        display: inherit !important;
    }

    .custom-tab-overflow{
        overflow-x:hidden;
    }

    .custom-tab-overflow {
        overflow-x: hidden;
    }

        .custom-tab-overflow .custom-overflow-tab {
            overflow-x: scroll;
        }
}
