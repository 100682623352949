section {
    width: 100%;
}

.main-title {
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 42px;
}

.show-forgot-landscape-mobile {
    display: none;
}

.w-15 {
    width: 15%;
}

.w-30 {
    width: 30%;
}

.width-24 {
    width: 24px;
}

.width-32 {
    width: 32px;
}

.width-56 {
    width: 56px;
}

.w-70 {
    width: 70%;
}

.width-140 {
    width: 140px;
}

.width-147 {
    width: 147px;
}

.width-300 {
    width: 300px;
}

.w-90 {
    width: 90% !important;
}

.width-200 {
    width: 200px;
}

.normal-row {
    line-height: 22px;
}

.margin-bottom-24 {
    margin-bottom: 24px;
}

.margin-bottom-8 {
    margin-bottom: 8px;
}

.margin-bottom-19 {
    margin-bottom: 19px;
}

.margin-top-24 {
    margin-top: 24px;
}

.margin-bottom-40 {
    margin-bottom: 40px;
}

.margin-bottom-48 {
    margin-bottom: 48px;
}

.margin-bottom-62 {
    margin-bottom: 62px;
}

.margin-top-8 {
    margin-top: 8px;
}

.margin-top-16 {
    margin-top: 16px;
}

.margin-bottom-16 {
    margin-bottom: 16px;
}

.margin-top-24 {
    margin-top: 24px;
}

.margin-bottom-78 {
    margin-bottom: 78px;
}

.margin-bottom-8 {
    margin-bottom: 8px;
}

.margin-top-30 {
    margin-top: 30px;
}

.margin-top-60 {
    margin-top: 60px;
}

.bottom-8 {
    bottom: 8px;
}

.padding-8 {
    padding: 8px;
}

.padding-16 {
    padding: 16px;
}

.padding-left-16 {
    padding: 16px;
}

.padding-16-8 {
    padding: 16px 8px;
}

.padding-16-0 {
    padding: 16px 0;
}

.margin-bottom-32 {
    margin-bottom: 32px;
}

.margin-bottom-4 {
    margin-bottom: 4px;
}

.margin-left-6 {
    margin-left: 6px;
}

.margin-left-8 {
    margin-left: 8px;
}

.margin-left-12 {
    margin-left: 12px;
}

.margin-left-18 {
    margin-left: 18px;
}

.margin-right-20 {
    margin-right: 20px;
}

.margin-right-30 {
    margin-right: 30px;
}

.margin-left-20 {
    margin-left: 20px;
}

.padding-left-30 {
    padding-left: 30px;
}

.cursor-pointer {
    cursor: pointer;
}

.border-bottom-EBEBEB {
    border-bottom: 2px solid #ebebeb;
}

.border-bottom-EBEBEB-thin,
.border-top-EBEBEB-thin {
    border-bottom: 1px solid #ebebeb;
}

.border-bottom-FF8568 {
    border-bottom: 2px solid #ff8568;
}

.modal-header>button>span {
    color: #182c34;
}

.tab-selected {
    border-bottom: 2px solid #ff8568 !important;
}

.tab-selected div {
    font-weight: bold !important;
    color: #182c34 !important;
}

.form-check-input:checked {
    background-color: #1e9f98;
    border-color: #1e9f98;
}

.tab div {
    color: #2d5362 !important;
}

.tab-disabled div {
    color: #c2cbd1 !important;
}

.page-link {
    color: #182c34;
    border-color: white;
}

.text-transform-uppercase {
    text-transform: uppercase;
}

.text {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 8px;
}

.overflow-y-auto {
    overflow-y: auto;
}

.height-24 {
    height: 24px;
}

.height-34 {
    height: 34px;
}

.min-height-540 {
    min-height: 540px;
}

.text-color-2D5362 {
    color: #2d5362;
}

.text-color-C2CBD1 {
    color: #c2cbd1;
}

.text-color-212529 {
    color: #212529;
}

.text-color-182C34 {
    color: #182c34;
}

.text-color-848A8C {
    color: #848a8c;
}

.background-FAFAFA {
    background: #fafafa;
    background-color: #fafafa;
}

.background-FFEAE5 {
    background: #FFEAE5;
    background-color: #FFEAE5;
}

.background-10B9F4 {
    background: #10B9F4;
    background-color: #10B9F4 !important;
}

.search-button:hover {
    color: #182c34;
}

.background-FFEAE6 {
    background-color: #ffeae6;
}

input[type="checkbox"] {
    border-radius: 2px;
    accent-color: #1ea099;
    width: 13px;
    height: 13px;
}

.break-all {
    word-break: break-all;
}

input[type="checkbox"]:checked,
input[type="checkbox"]:checked:after {
    background-color: #1ea099;
}

.collapse-config .collupse-button.btn-primary:active,
.collapse-config .collupse-button.btn-primary:focus,
.collapse-config .collupse-button.btn-primary:hover,
.collapse-config .collupse-button.btn.btn-primary {
    background-color: #fff !important;
    box-shadow: none;
    padding: 0;
    margin-bottom: 0;
}

.collapse-config .card {
    border-color: #fff !important;
}

.collapse-config .card-body {
    padding: 0;
}

.background-EFF8F7 {
    background: #eff8f7;
}

.background-E0E7EB {
    background: #e0e7eb;
}

.background-FFFFFF {
    background: #ffffff;
}

.color-FFFFFF {
    color: #ffffff;
}

.badge.rounded-pill.bg-secondary {
    background-color: #e0e7eb !important;
}

.badge.rounded-pill.bg-info {
    background-color: #ff8568 !important;
}

.concept-component .dropdown>button {
    background-color: #ffffff !important;
    color: #182c34;
    display: flex;
    justify-content: flex-end;
}

.btn-check:checked+.btn-secondary,
.btn-check:active+.btn-secondary,
.btn-secondary:active,
.btn-secondary.active,
.show>.btn-secondary.dropdown-toggle {
    color: #182c34;
}

.concept-component .dropdown>.btn-check:focus+.btn-secondary,
.concept-component .dropdown>.btn-secondary:focus {
    box-shadow: none;
}

.concept-component .dropdown-menu {
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border: 1px solid #ebebeb;
}

.concept-component .dropdown-menu.show {
    top: 22px !important;
    transform: translate3d(0px, 43px, 0px) !important;
    width: 100%;
}

.background-EFF8F7 {
    background: #eff8f7;
}

.background-1E9F98 {
    background-color: #1e9f98;
}

.popOverItem:hover {
    background: #26cac1;
}

.popOverItem:hover {
    background: #26cac1;
}

.btn-primary {
    background-color: #26cac1 !important;
    border: none;
    border-radius: 4px;
    padding: 9px 12px;
}

.btn-primary:hover {
    background-color: #46dcd4 !important;
    border: none;
}

.btn-primary:active {
    background-color: #1e9f98 !important;
    border: none;
}

.btn-primary:focus {
    background-color: rgba(38, 202, 196, 0.5) !important;
    border: none;
}

.btn-primary:disabled {
    background-color: #b4e6e3 !important;
    border: none;
}

.btn-secondary {
    background-color: #e0e7eb !important;
    border: none;
    border-radius: 4px;
    padding: 9px 12px;
}

.badge-secondary {
    background-color: #e0e7eb !important;
}

.btn-secondary:hover {
    background-color: #f6f8f9 !important;
    border: none;
}

.btn-secondary:active {
    background-color: #c1cfd7 !important;
    border: none;
}

.btn-secondary:focus {
    background-color: rgba(165, 185, 197, 0.5) !important;
    border: none;
}

.btn-secondary:disabled {
    background-color: #eceeef !important;
    border: none;
}

.page-item:hover {
    background-color: #f6f8f8;
    border-radius: 4px;
}

.page-item.active,
.page-item.active .page-link {
    background-color: #26cac1 !important;
    border-radius: 4px;
    border: none;
}

.popover-item-config,
.popover-item-search-config {
    background: #ffffff;
    box-shadow: 2px 2px 16px rgba(156, 156, 156, 0.25);
    border-radius: 8px;
}

.border-radius-4 {
    border-radius: 4px;
}

.border-FFFFFF {
    border: 1px solid #ffffff;
}

.border-CED4DA {
    border: 1px solid #ced4da;
}

.border-E0E7EB {
    border: 1px solid #e0e7eb;
}

.border-shadow-EAEAEA {
    box-shadow: inset 0px 3px 6px rgba(189, 189, 189, 0.25);
}

.border-0 {
    border-width: 0;
}

.font-weight-600 {
    font-weight: 600;
}

.filter-156-156-156-025 {
    filter: drop-shadow(2px 2px 16px rgba(156, 156, 156, 0.25));
}

.input-group-text {
    background-color: #e0e7eb;
}

.chatmessageShellConfig>div {
    border-radius: 4px;
    padding: 16px 16px 16px;
}

.breadcrumb-item+.breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #c2cbd1;
    content: var(--bs-breadcrumb-divider, "|");
    margin-left: -1px;
}

.breadcrumb-item.active {
    color: #182c34;
}

.border-top-color-EBEBEB {
    border-top-color: #ebebeb;
}

.border-bottom-color-EBEBEB {
    border-bottom-color: #ebebeb;
}

.font-size-14 {
    font-size: 14px !important;
}

.font-size-11 {
    font-size: 11px !important;
}

.line-height-20 {
    line-height: 20px !important;
}

.line-height-10 {
    line-height: 10px !important;
}


@media (min-width: 768px) {
    .width-100 {
        width: 100% !important;
    }
}

@media (max-width: 767px) {
    .chatmessageShellConfig {
        width: 100%;
        bottom: 0px;
    }

    .chatmessageShellConfig>div.right-message {
        margin-right: 16px;
        margin-left: 70px;
    }

    .chatmessageShellConfig>div.left-message {
        margin-right: 70px;
        margin-left: 16px;
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    .chat-screen-config {
        height: 177px;
    }

    .chatmessageShellConfig {
        bottom: 16px;
    }

    .chatmessageShellConfig>div.right-message {
        margin-right: 16px;
        margin-left: 70px;
    }

    .chatmessageShellConfig>div.left-message {
        margin-right: 70px;
        margin-left: 16px;
    }
}

@media (min-width: 1200px) {
    .chatmessageShellConfig {
        width: 95%;
        bottom: 40px;
    }

    .chatmessageShellConfig {
        width: 95%;
        bottom: 40px;
    }

    .context-quote .chatmessageShellConfig {
        width: 95%;
        bottom: 0px;
    }

    .chatmessageShellConfig>div.right-message {
        margin-right: 16px;
        margin-left: 70px;
    }

    .chatmessageShellConfig>div.left-message {
        margin-right: 70px;
        margin-left: 16px;
    }
}

@media (max-width: 575px) and (orientation: landscape) {
    article {
        height: 80vh;
    }

    .label {
        display: none !important;
    }
}

@media (min-width: 576px) and (orientation: portrait) {}

@media (max-width: 575px) {
    .label {
        display: none !important;
    }

    footer {
        height: 60px;
    }
}

@media (max-width: 991px) and (orientation: landscape) {
    .display-none {
        display: none !important;
    }

    .show-forgot-landscape-mobile {
        display: block;
    }

    .layout-mob-landscape {
        margin-bottom: 0;
    }

    .main-title {
        font-size: 22px;
    }

    .container-custom {
        margin-top: 59.1px;
    }
}

.config-btn-inprocess.moduleSummery.false {
    width: 100% !important;
}

.config-btn-inprocess.moduleSummery.btn.btn-primary {
    width: 100% !important;
}