.headerconfig {
    color: #182C34 !important;
}

.darkHeaderconfig {
    color: #fff;
}

.size-26 {
    font-family: Spartan;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 36px;
    margin-bottom: 24px;
}

.size-18 {
    font-family: Spartan;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
}

.size-16 {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 1px;
}

.size-14 {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 1px;
}

.margin-bottom-8 {
    margin-bottom: 8px;
}

.iconShellConfig {
    width: 32px;
}

.textUppercase {
    text-transform: uppercase;
}

.textCapitalize {
    text-transform: capitalize;
}

@media (max-width: 767px) {
    .iconShellConfig {
        width: 40px;
        padding-right: 15px;
    }
}

.imageText {
    margin-top: -5px;
}