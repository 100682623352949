.form-group {
  position: relative;
  /* height: 200px;
  width: 400px; */
}

#input {
  height: 200px;
  width: 400px;
}
.icon-mic {
  position: absolute;
  background: #c7ecee;
  clip-path: circle();
  width: 40px;
  height: 40px;
  right: 16px;
  bottom: 2px;
}

.btn:hover {
  color: #182c34;
}
