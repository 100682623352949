.mn-image {
  width: 8.125rem;
  height: 1.813rem;
}

.title-font {
  border-radius: 4px;
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #2d5362;
}

.input-font {
  border-radius: 4px;
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #2d5362;
}
