.App {
    font-family: Nunito;
    text-align: center;
}

/* for table header row */
.rdt_TableHeadRow {
    background: #eff8f7 !important;
    border-radius: 0px;
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #2d5362;
}

/* for table data cell */
.rdt_TableCell {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 26px;
    color: #182c34;
}