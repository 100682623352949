.tooltipIconConfig{
    height: 56px;
}
.tedConfigRight{
    top: -16px;
    left: -41px;
}

.tedConfigLeft{
    top: -16px;
    left: 4px;
}

.arrowLeft{
    content: " ";
    top: 10%;
    left: -10px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid;
}

.arrowRight{
    content: " ";
    top: 10%;
    right: -10px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid;
}




