@media (min-width: 1200px) {
    .list-config {
        gap: 30px;
        margin-bottom: 20px;
    }

    .list-config>div {
        width: 30%;
        background: #FFFFFF;
        box-shadow: 2px 2px 16px rgba(156, 156, 156, 0.25);
        border-radius: 8px;
        /*         padding: 24px 16px; */
    }
}

@media (min-width: 1200px) {
    .dark-config {
        gap: 30px;
        margin-bottom: 20px;
    }

    .dark-config>div {
        width: 30%;
        background: #1C2930;
        box-shadow: 2px 2px 16px rgba(156, 156, 156, 0.25);
        border-radius: 8px;
        /*         padding: 24px 16px; */
    }
}

@media (max-width: 767px) {
    .d-flex.list-config.custom-flex-row {
        flex-direction: row !important;
        display: inherit !important;
    }

    .dashboard-card-shadow {
        box-shadow: 2px 2px 16px rgb(156 156 156 / 25%);
        border-radius: 8px;
        margin-bottom: 10px;
    }
}

@media (max-width: 767px) {
    .d-flex.list-config.custom-flex-row {
        flex-direction: row !important;
        display: inherit !important;
    }

    .dashboard-card-shadow {
        box-shadow: 2px 2px 16px rgb(156 156 156 / 25%);
        border-radius: 8px;
        margin-bottom: 10px;
    }
}