a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

.top-distance-menu {
  position: -webkit-sticky;
  position: sticky;
  z-index: 10;
  background-color: white;
}

.navbar {
  padding-top: 24px;
  padding-bottom: 1.5rem;
}

html {
  font-size: 14px;
}

.ml-5-custom {
  margin-left: 5px;
}


@media (min-width: 768px) {

  .navbar {
    padding-bottom: 1.5rem;
  }

}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }

  .navbar-expand-md .navbar-collapse {
    display: none !important;
  }
}

.icon-column {
  float: left;
  width: 25%;
  padding: 5px;
}

.imageStyle {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  width: 3%;
}

.navbar-toggler {
  border: 1px solid #fff;
}