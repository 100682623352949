@media (min-width: 1200px) { 
    .chat-shell-config{
        border-radius: 4px;
        height: 693px; 
        margin-bottom: 39px;
    }

    .additionaslResourcesCustomConfig{
        height: 235px !important;
    }
}

@media (max-width: 1199px) { 
    .chat-shell-config{
        border-radius: 4px;
        margin-bottom: 48px;
        margin-top: 48px;
    }
}
