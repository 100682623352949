.collapsTitleConfig {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    text-decoration: underline;
}

.btnPrimary {
    background-color: #26cac1 !important;
    border: none;
    border-radius: 4px;
    padding: 9px 12px;
}

.checkedDisabledCheckbox {
    opacity: 0.5;
}

@media (max-width: 767px) {
    .read-transcript-adjusment {
        padding-bottom: 10px;
    }
}