.popOverItem{
    height: 50px;
    width: 190px;
    padding: 12px 16px 12px 16px;
}
.popOverItem:first-of-type{
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.last-item{
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.popover-body{
    padding: 0;
}
.popover{
    border: none;
}
.popover-item-config{
    z-index: 100;
}
@media (max-width: 767px){
    .popover-item-config{
        z-index: 100;
        left: -114px;
        top: -14px;    
    }
  }

@media (min-width: 768px) and (max-width: 1199px){
    .popover-item-config{
        z-index: 100;
        left: -114px;
        top: -100px;    
    }
  }

  @media (min-width: 1200px){
    .popover-item-config{
        left: 58px;
        top: -30px;    
    }
  }

  .rightImg{
      background: #1c2930 !important;
      border: 0px solid;
  }