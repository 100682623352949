.title {
    font-family: Nunito;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
}

.data {
    font-family: Nunito;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 26px;
}

.scoreData {
    font-family: Nunito;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
}