.video-player-config {
  width: 100% !important;
}

@media (max-width: 767px) and (orientation: portrait) {
  .react-player {
    height: 177px !important;
  }

  .react-player video {
    height: unset !important
  }
}


@media (min-width: 768px) and (max-width: 1199px) {
  .video-player-config {
    /*   height: 537.5px !important; */
  }
}

@media (min-width: 1200px) {
  .video-player-config {
    height: 457.5px !important;
  }
}

audio::-webkit-media-controls-timeline,
video::-webkit-media-controls-timeline {
  display: block;
}

video::-webkit-media-controls-panel {
  display: flex;
  direction: ltr;
  flex-direction: row;
  align-items: center;
  -webkit-user-select: none;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 0;
  overflow: hidden;
  height: 48px;
  text-align: right;
  border-radius: 4px;
}

video::-webkit-media-controls-panel {
  background-color: linear-gradient(180deg, #182C34 100%, #182C34 0%);

}