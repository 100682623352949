.counter{
    font-size: 16px;
    color: #FFFFFF;
    background-color: #475E70;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    text-align: center;
}
.card-config{
    margin-bottom: 32px;
}

@media (min-width: 576px) {
    .card-config .card-config{
        margin-top: 16px;
    }
}

@media (max-width: 575px) {
    .card-config .card-body{
        padding: 8px 8px; 
    }
    .header-config{
        margin-top: 8px;
    }
}
 
@media (min-width: 576px){
    .card-config .card-body{
        padding: 16px 8px; 
    }
}

