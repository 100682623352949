.button-font-style {
    color: black;
    font-weight: 600;
}

.text {
    font-family: Nunito;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
}