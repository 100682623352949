.forgot-password {
  font-family: Nunito;
  font-style: normal;
  font-size: 16px;
  line-height: 26px;
  overflow-y: auto;
  text-decoration: underline;
}

.mn-image {
  width: 8.125rem;
  height: 1.813rem;
}

.title-font {
  border-radius: 4px;
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #2d5362;
}

.input-font {
  border-radius: 4px;
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #2d5362;
}

.footer-font{
  border-radius: 4px;
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}


.w-450{
  width: 450px;
  min-width: 450px;
}


@media (min-width: 992px){
  .login-panel-config{
    width: 450px;
    min-width: 450px;  
  }  
}
@media (max-width: 991px){
  .login-panel-config{
    width: 320px;
    min-width: 320px;  
  } 
}


@media (max-width: 991px)  and (orientation: portrait) {
  .login-panel-config{
    width: 320px;
    min-width: 320px;  
  } 
}
@media (max-width: 991px)  and (orientation: landscape) {
  .login-panel-config{
    width: 320px;
    min-width: 320px;  
  } 
}