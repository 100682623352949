@media (max-width: 1199px) { 
    .leftSideConfig{
        margin-top:48px;
    }
    .leftSideConfig>.left-side-engage-inner{
        padding-left:0;
    }
 }

 @media (min-width: 1200px) { 
    .leftSideConfig>.left-side-engage-inner{
        padding-left:30px;
    }
 }

