.text-config {
    font-family: Nunito;
    font-style: normal;
    font-size: 16px;
    line-height: 26px;
    overflow-y: auto;
}

.margin-bottom-4 {
    margin-bottom: 4px;
}

.chatMessageText {
    left: 16px;
    top: 16px;
    -webkit-user-input: none;
    -moz-user-input: none;
    -ms-user-input: none;
}

::-webkit-scrollbar {
    width: 0;
    background: transparent;
}

.buttonText {
    color: black !important;
}

.breadDark {
    margin-left: 15px;
}

.stage2 {
    color: #2d5362 !important;
}

/* ::-webkit-scrollbar-thumb {
    background: #FF0000;
} */