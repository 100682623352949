.concept-select-config{
    height: 64px;
}
.concept-subtitle{
    font-size: 18px;    
}

.warningHeight{
    height: 76px;
}

@media (min-width: 1200px) {
    .contentShellLeftConfig{
        width:100% !important;
    }
    .marginTopShellMain{
        top: 76px;
        bottom: 88px;
        z-index: 100;
    }
    .height400{
        height: 693px !important;    
        }
  }

  @media (max-width: 767px) {
    .transcriptConfig{
        margin-top: 145px;
    }
    .height400{
        height: 693px !important;    
        }
  }

  @media (max-width: 767px) and (orientation:portrait){

    .chatScreenConfigThinkIntro{
        height: 265px !important;
        }
    .height400{
        height: 400px !important;    
        }
    }
    
    @media (min-width: 768px) and (max-width: 1199px){ 
    .chatScreenConfigThinkIntro{
        height: 200px !important; 
    }
    .height400{
        height: 450px !important;    
        }
}