.font-style {
    font-family: Spartan;
    font-size: 28px;
}

.header-style {
    background: #eff8f7;
    border-radius: 4px;
    font-family: Nunito;
    font-style: normal;
    font-weight: lighter;
    font-size: 14px;
    line-height: 24px;
    color: #2d5362;
}

.cell-style {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 26px;
    color: #182c34;
}

.header-margin {
    margin-bottom: 2px;
}

.user-header {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    font-weight: 600;
    color: #182c34;
}

.horizontal-rule {
    color: #26cac1;
    margin: 0px;
}

.meeting-details-wrapper {
    display: flex;
    margin-top: 2px;
    justify-content: space-between;
    width: 100%;
}

.meeting-name {
    padding-bottom: 2px;
    border-bottom: 2px solid #26cac1;
}

.meeting-date {
    padding-bottom: 2px;
    border-bottom: 2px solid #26cac1;
}

@media (min-width: 320px) and (max-width: 576px) {
    .meeting-details-wrapper {
        flex-direction: column;
    }
}