.date-picker-wrapper {
  z-index: 0 !important;
}

.date-picker-input {
  text-align: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  appearance: none;
  border: 1px solid rgb(206, 212, 218);
  border-radius: 0.25rem;
  color: rgb(33, 37, 41);
  outline: none;
  width: 100% !important;
}
.progress-bar {
  background-color: #42c0bb !important;
}
