.intro-text {
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 26px;
}

.filter-text {
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 21.82px;
}

.sort-by-text {
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 21.82px;
    text-align: end;
}

.dropdown-border {
    border: 1px solid #e0e7eb;
}

.date-range {
    text-align: center;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    appearance: none;
    border: 1px solid rgb(206, 212, 218);
    border-radius: 0.25rem;
    color: rgb(33, 37, 41);
    outline: none;
}

@media (min-width: 320px) and (max-width: 576px) {
    #dropdown {
        display: flex;
        flex-direction: column;
    }
    #sortByWrapper {
        margin-top: 10px;
    }
    #sortby {
        text-align: left;
    }
}
