.header-text{
    font-family: Nunito;
    font-weight: bold;
}

.body-text {
    font-family: Nunito;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
}