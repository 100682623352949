.listItemConfig{
    height: 80px;
}


@media (max-width: 1199px) { 
    .search-content-config-lg{
        display: none !important;
    }
 }

 @media (min-width: 1200px) { 
    .search-content-config-md{
        display: none !important;
    }
 }
 
