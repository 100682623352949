.modal-header{
    height: 72px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px 16px 16px;
    left: calc(50% - 500px/2);
    top: calc(50% - 72px/2 - 175.5px);
}
.modal-header button{
    border: none;
    background: none;
}
.modal-header>button>span{
    font-size: 40px;
    margin-bottom: 45px;
    height: 27px;
    display: block;
}
.modal-body{
    padding: 24px 16px;  
}