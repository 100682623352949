.concept-select-config{
    height: 64px;
}
.concept-subtitle{
    font-size: 18px;    
}



@media (min-width: 1200px) {
    .contentShellLeftConfig{
        width:100% !important;
    }
    .marginTopShellMain{
        top: 76px;
        bottom: 88px;
        z-index:100;
    }
    .warningHeight{
        height: 76px;
    }
  }

  @media (max-width: 767px) {
    .transcriptConfig{
        margin-top: 145px;
    }
  }

  @media (max-width: 767px) and (orientation:portrait){
    .chatScreenConfigThinkIntro{
        margin-bottom: 45px;
        }
    }
    
    @media (min-width: 768px) and (max-width: 1199px){ 
    .chatScreenConfigThinkIntro{
      margin-bottom: 45px;
    }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
::-webkit-scrollbar-thumb {
  background: #888; 
}

::-webkit-scrollbar-thumb:hover {
  background: #555; 
}