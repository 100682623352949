@media (min-width: 1200px) {
    .header-config {
        height: 104px;
    }

    .content-config {
        height: 84px;
        padding: 16px 0;
    }

    .content-config>div {
        grid-gap: 9px;
    }

    .content-config-borders {
        border-top-style: solid;
        border-bottom-style: solid;
        border-top-width: 1px;
        border-bottom-width: 1px;
    }

    .dashboard-card .config-btn-inprocess {
        width: 128px;
    }

    .config-btn-inprocess .width-128 {
        width: 104px;
    }
}

.dashboard-card.card-body {
    padding-bottom: 24px;
}

.subtitle-config {
    height: 24px;
    /* padding-bottom: 35px; */
}

.content-config .text-config>p {
    width: 31ch;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.disabled-link {
    pointer-events: none;
}

.btn-secondary.btn-secondary-disable:hover {
    background-color: #e0e7eb !important;
}

@media (max-width: 767px) {

    .dashboard-card .config-btn-inprocess {
        width: 100%;

    }

    .dashboard-card .config-btn-inprocess:not(:last-child) {
        margin-right: 20px;
    }

    .dashboard-card .image-wrapper {
        padding: 5px;
    }

    .subtitle-config {
        padding-bottom: 35px;
    }

    .card-text-spacing {
        display: inherit !important;
        vertical-align: middle !important;
        padding-top: 15px;
    }
}

.popOver {
    padding: 18px !important;
    font-family: Nunito;
    font-style: normal;
    font-size: 15px;
}