.imageSize152{
    width: 152px;
    height: 152px;    
}
.imageSize49{
    width: 49px;
    height: 49px;    
}
.imageSize36{
    width: 36px;
    height: 36px;    
}
.profileImageConfig{
    margin-top: 24px    
}