.title {
    font-family: Nunito;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
}

.description {
    font-family: Nunito;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: "grey";
    overflow: "hidden";
    text-overflow: "ellipses";
}

.numbers {
    font-family: Nunito;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
}

.practice-selection {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.link-wrapper {
    width: 25%;
}

.practice-library-wrapper {
    display: flex;
}

@media (min-width: 320px) {
    .practice-selection {
        flex-direction: column;
        align-items: unset;
    }
    .link-wrapper {
        width: 100%;
        margin-top: 1rem;
    }
    .practice-library-wrapper {
        display: flex;
        flex-direction: column;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .practice-selection {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .link-wrapper {
        width: 35%;
    }
    .practice-library-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}

@media (min-width: 1024px) {
    .practice-selection {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .link-wrapper {
        width: 18%;
    }
    .practice-library-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 45%;
    }
}

@media (min-width: 320px) and (max-width: 576px) {
    .added-text{
        font-size: 12px;
    }
}
