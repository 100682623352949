@media (min-width: 1200px) { 
    .doOutConfig{
        width:100% !important;
    }
}

@media (max-width: 767px) and (orientation:portrait){
.chatScreenConfigDo{
    height: 700px !important;
    }
}

  @media (min-width: 768px) and (max-width: 1199px){ 
    .chatScreenConfigDo{
        height: 400px !important; 
    }
}

