div.fileUpload{
    position: relative;
    max-width: 100%;
    height: 250px;
    border: 2px dashed gray;
  }
  div.fileUpload p{
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 170px;
    color: #9e9595;
    font-family: Arial;
  }
  div.fileUpload input{
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
  }
  div.fileUpload button{
    margin: 0;
    color: #fff;
    background: #16a085;
    border: none;
    width: 250px;
    height: 35px;
    margin-top: 10px;
    margin-left: -4px;
    border-radius: 4px;
    transition: all .2s ease;
    outline: none;
  }
  div.fileUpload button:hover{
    background: #149174;
      color: white;
  }
  div.fileUpload button:active{
    border:0;
  } 
  div.fileUpload button:disabled,
  button[disabled]{
    background-color: #cccccc;
    color: #666666;
  }

@media (max-width: 767px) {
    [class*="col-"] {
        width: 100%;
    }
}
