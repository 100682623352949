.main-wrapper {
    position: sticky;
    bottom: 60px;
    z-index: 3;
}

/* for drawer */
.wrapper {
    position: absolute;
    right: -84px;
    margin-bottom: 10px;
    bottom: 260px;
    width: 300px;
    z-index: 1;
    background: #fafafa;
    border-radius: 8px;
    opacity: 0;
    transition: all 0.4s;
    visibility: hidden;
}

/* for name circle */
.circle {
    background: #ff8568 !important;
    width: 50px;
    border-radius: 50px;
    position: fixed;
    height: 50px;
    display: flex;
    /* or inline-flex */
    align-items: center;
    justify-content: center;
}

.ted-chat {
    background: #ff8568 !important;
    width: 50px;
    border-radius: 50%;
    height: 50px;
    display: flex;
    /* or inline-flex */
    align-items: center;
    justify-content: center;
}

.user-icon-font {
    color: #182c34;
    font-weight: 700;
}

.chat-btn {
    position: fixed;
    cursor: pointer;
    bottom: 5px;
    padding: 20px;
}

.chat-btn .close {
    display: none;
}

.chat-btn i {
    transition: all 0.9s ease;
}

#check:checked~.chat-btn i {
    display: block;
    pointer-events: auto;
    transform: rotate(180deg);
}

#check:checked~.chat-btn .comment {
    display: none;
}

#check:checked~.wrapper {
    opacity: 1;
}

#check {
    display: none !important;
}

.chat-btn i {
    font-size: 22px;
    color: #fff !important;
}

.chat-btn {
    width: 50px;
    height: 50px;
    display: flex;
    position: fixed;
    right: 25px;
    padding: 20px;
    z-index: 2;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    background-color: gray;
    color: #fff;
    font-size: 22px;
    border: none;
}

.actions {
    background: white;
    width: 200px;
    border-radius: 10px;
}

.actions-font {
    color: #182c34;
    font-weight: 400;
    font-size: 16px;
}

#actions:after {
    content: "";
    position: absolute;
    margin-top: 25px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid white;
    transform: rotate(-90deg);
    left: 65px;
    top: 10px;
}

.button-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 19.1px;
    text-align: center;
    color: #182c34;
}

#button:hover {
    font-weight: 600;
    font-size: 14px;
    line-height: 19.1px;
    text-align: center;
    color: #182c34;
}

@media (min-width: 320px) and (max-width: 576px) {
    .wrapper {
        /* right: 8px; */
        position: fixed;
        /* bottom: 60px; */
        margin-right: 95px;
        margin-top: 60px;
        top: 25px;
        height: 200px;
    }

    .chat-btn {
        bottom: 8px;
        right: 8px
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .wrapper {
        position: fixed;
        right: 20px;
        bottom: 60px;
    }

    .chat-btn {
        bottom: 8px;
        right: 8px
    }
}

@media (min-width: 992px) and (max-width: 1024px) {
    .wrapper {
        position: fixed;
        right: 20px;
        bottom: 60px;
    }

    .chat-btn {
        bottom: 8px;
        right: 8px
    }
}

@media (min-width: 1024px) and (max-width: 1440px) {
    .wrapper {
        position: fixed;
        right: 20px;
        bottom: 60px;
    }

    .chat-btn {
        bottom: 8px;
        right: 8px
    }
}

@media (min-width: 1440px) and (max-width: 2560px) {
    .wrapper {
        position: fixed;
        right: 20px;
        bottom: 60px;
    }

    .chat-btn {
        bottom: 8px;
        right: 8px
    }
}

@media (orientation: landscape) {
    .wrapper {
        /* position: fixed;
        right: 20px;
        bottom: 60px; */
        margin-top: 60px;
        top: 25px;
        height: 200px;
        position: fixed
    }

    .chat-btn {
        bottom: 8px;
        right: 8px
    }
}

.chat-res {
    margin-right: 5%;
    top: 25px;
}

@media (min-width: 360px) and (max-width: 900px) {
    .chat-res {
        /* margin-right: 17%; */
        top: 25px;
    }
}

@media (min-width: 768px) and (max-width: 1120px) {
    .chat-res {
        /* margin-right: 17%; */
        top: 25px;
    }
}