.input-group-config{
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;    
}
.input-group-config>input{
    height: 48px; 
}
