.popOverItem{
    height: 50px;
    width: 190px;
    padding: 12px 16px 12px 16px;
}
.popOverItem:first-of-type{
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.last-item{
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.popover-body{
    padding: 0;
}
.popover{
    border: none;
}
.popover-item-search-config{
    z-index: 100;
}

@media (max-width: 1199px){
    .popover-item-search-config{
        z-index: 100;
        left: -129px;
        top: -41px;    
    }
  }

  @media (min-width: 1200px){
    .listItemConfig .popover-item-search-config{
        left: 40px;
        top: -10px;    
    }
    .popover-item-search-config{
        left: -100px;
        top: -24px;    
    }
  }