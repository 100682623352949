
@media (min-width: 1200px) {
    .contentShellLeftConfig{
        width:100% !important;
    }
  }

  @media (max-width: 767px) {
    .transcriptConfig{
        margin-top: 145px;
    }
  }

  @media (max-width: 767px) and (orientation:portrait){
    .chatScreenConfigThinkIntro{
        height: 265px !important;
        overflow-y: auto;
        margin-top: 20px;
        }
        .ThinkIntroComponent img{
          display: none;
        }
    }
    
      @media (min-width: 768px) and (max-width: 1199px){ 
        .chatScreenConfigThinkIntro{
            height: 200px !important; 
        }
        .chatmessageShellConfig{
          position: absolute;
        }
    }