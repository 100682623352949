.btn-primary-custom {
    height: 40px !important;
    border: none;
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
}
@media (min-width: 1200px) {
    .btn-primary-custom {
        width: 100% !important;
    }
}
@media (min-width: 768px)  and (max-width: 1199px){ 
    .btn-primary-custom {
        width: 210px !important;
        float: right;
        margin-bottom: 22px;
    }

 }

 @media (max-width: 767px){ 
    .btn-primary-custom {
        width: 100% !important;
        margin-bottom: 22px;
    }

 }