.font-style {
    font-family: Spartan;
    font-size: 12px;
}

.card-title {
    line-height: 0;
}

.header-time {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 0px !important;
}

.notification-content {
    font-size: 14px;
    text-align: justify;
    text-justify: inter-word;
}

.no-notifications-header {
    font-family: Spartan;
    font-weight: 600;
    font-size: 16px;
}

.no-notifications-text {
    font-family: Spartan;
    font-size: 12px;
    text-align: center;
}